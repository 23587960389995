// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-updates-js": () => import("./../../../src/pages/updates.js" /* webpackChunkName: "component---src-pages-updates-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/BlogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */)
}

